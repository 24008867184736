import Lenis from '@studio-freight/lenis'
import gsap from "gsap";

// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

// const lenis = new Lenis({
//   duration: 0,
// })
// lenis.on('scroll', (e) => {
// })
// function raf(time) {
//   lenis.raf(time)
//   requestAnimationFrame(raf)
// }

// requestAnimationFrame(raf)
// アンカーリンクを取得
const anchors = document.querySelectorAll(".js-anchor");
// クリック時に目的の箇所までスクロールする
anchors.forEach((anchor) => {
  anchor.addEventListener("click", (e) => {
    // urlを変更しないようにする
    e.preventDefault();
    const to = e.target.getAttribute('href')
    // スクロール
    lenis.scrollTo(to);
  });
})



// const pieces = document.querySelectorAll(".piece");
// const pieceBg = document.querySelector(".piece-bg");
// function pieceAnime() {
//   gsap.set(pieceBg, {opacity:0});
//   const tl = gsap.timeline(); 
//   pieces.forEach((piece,i) => {
//     gsap.fromTo(piece, {
//       x: gsap.utils.random(-100, 100),
//       y: gsap.utils.random(-100, 100),
//       opacity: 0,
//       // delay : gsap.utils.random(0.2, 2),
//       duration: .5,
//       delay: 0,
//     },{
//       x: 0,
//       y: 0,
//       opacity: 1,
//     })
//   })
//   tl.to(pieceBg, { opacity: 1})
// }
// pieceAnime()
// setInterval(pieceAnime,8000)

const dots = document.querySelectorAll('.c-dots')
const dotClass = "js-dot"

dots.forEach((dotsBox) => {
  const col = dotsBox.getAttribute('data-col')
  const row = dotsBox.getAttribute('data-row')
  dotsBox.style.gridTemplateColumns = "repeat("+col+",4rem)";
  dotsBox.style.gridTemplateRows =  "repeat("+row+",4rem)";
  for(let i = 0; i < col; i++) {
    for(let j = 0; j < row; j++) {
      const tempelem = document.createElement('div');
      tempelem.classList.add(dotClass)
      dotsBox.appendChild(tempelem)
      gsap.set(tempelem, {
        x: gsap.utils.random(-20, 30),
        y: gsap.utils.random(-20, 30),
        opacity:1,
        // scale: gsap.utils.random(.25, 2.5),
      })
      gsap.to(tempelem, {
        x: 5,
        y: 5,
        // scale: 1,
        opacity: .5,
        repeat: -1,
        ease: "power1.inOut",
        yoyo: true,
        duration: 3.5
      })

    }
  }

})

const fadeInEls = gsap.utils.toArray('.js-fadein-effect1');
if (fadeInEls.length != 0) {
  fadeInEls.forEach((box, index) => {
    gsap.fromTo(box, {
      y: 20,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      duration: 1.25,
      scrollTrigger: {
        trigger: box,
        start: 'top bottom',
        end: 'bottom top',
      }
    });
  });
}